import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getSurveyCakeQuestionnaireLink, setIsPageContentShow } from 'models/leadPage';

const leadPageRoute: CustomRoute = {
	path: '/:scVariable',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'leadPage' */ './component')],
			render: ([LeadPage]) => <LeadPage />,
			onEnter: async ({ params, next, store }) => {
				const { scVariable } = params;
				const PERSONAL_SC_VARIABLE_LENGTH = 6;

				if (scVariable.length === PERSONAL_SC_VARIABLE_LENGTH) {
					store.dispatch(getSurveyCakeQuestionnaireLink(scVariable as string));
					return null;
				}

				store.dispatch(setIsPageContentShow(scVariable.length !== PERSONAL_SC_VARIABLE_LENGTH));
				const children = await next();
				return children;
			},
		},
	],
};

export default leadPageRoute;
