import { ValidationRules } from './validationRules';
import { FormControl, FormErrors } from './formControl';
import { ValidatorFnType } from './formOption';

/**
 * 更新單一欄位的值
 *
 * @template T
 * @template R
 * @param {T} form
 * @param {keyof T} ctrlName
 * @param {R} value
 * @return {*}  {Partial<T>}
 */
const setCtrlValue = <T, R>(form: T, ctrlName: keyof T, value: R): Partial<T> =>
	({
		[ctrlName]: {
			...form[ctrlName],
			value,
		},
	} as Partial<T>);

/**
 * 更新整個表單
 *
 * @template T
 * @param {T} form
 * @param {Record<keyof T, unknown>} values
 * @return {*}  {T}
 */
const patchValue = <T extends object>(form: T, values: Record<keyof T, unknown>): T => {
	const formKeys = Object.keys(form);
	let newForm = { ...form };
	Object.keys(values).forEach(key => {
		if (formKeys.indexOf(key) > -1) {
			const newControl = setCtrlValue<T, unknown>(form, key as keyof T, values[key as keyof T]);
			newForm = {
				...newForm,
				...newControl,
			};
		}
	});
	return newForm;
};

/**
 * 設置單一欄位的錯誤
 *
 * @template T
 * @param {T} form
 * @param {keyof T} ctrlName
 * @param {FormErrors} errors
 * @return {*}  {Partial<T>}
 */
const setCtrlErrors = <T>(form: T, ctrlName: keyof T, errors: FormErrors): Partial<T> =>
	({
		[ctrlName]: {
			...form[ctrlName],
			errors,
		},
	} as Partial<T>);

/**
 * 取得單一欄位的錯誤，過程中會再次檢查欄位的錯誤
 *
 * @template T
 * @param {T} form
 * @param {keyof T} ctrlName
 * @return {*}  {(FormErrors | null)}
 */
const getCtrlErrors = <T>(form: T, ctrlName: keyof T): FormErrors | null => {
	let errObj = {};

	const formControl = form[ctrlName] as FormControl;
	const { options: formOption } = formControl;

	if (!formOption || !formOption.validators) {
		return null;
	}

	formOption.validators.forEach((v: unknown) => {
		if (typeof v === 'function') {
			errObj = { ...errObj, ...v(formControl.value) };
		}
	});

	const finalErrors = Object.keys(errObj).length > 0 ? errObj : null;

	return finalErrors;
};

/**
 * 更新單一欄位的錯誤
 *
 * @template T
 * @param {T} form
 * @param {keyof T} ctrlName
 * @return {*}  {Partial<T>}
 */
const updateCtrlValidity = <T>(form: T, ctrlName: keyof T): Partial<T> => {
	const errors = getCtrlErrors(form, ctrlName);
	return setCtrlErrors(form, ctrlName, errors);
};

/**
 * 更新整個表單的錯誤
 *
 * @template T
 * @param {T} form
 * @return {*}  {T}
 */
const updateValidity = <T extends object>(form: T): T => {
	let newForm = { ...form };
	Object.keys(form).forEach(key => {
		const ctrlName = key as keyof T;
		const newControl = updateCtrlValidity<T>(form, ctrlName);
		newForm = {
			...newForm,
			...newControl,
		};
	});
	return newForm;
};

/**
 * 檢測整個表單是否合格
 *
 * @template T
 * @param {T} form
 * @return {*}  {boolean}
 */
const isFormValid = <T extends object>(form: T): boolean => {
	let formValid = true;
	const list = Object.keys(form);

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < list.length; i++) {
		const ctrlName = list[i];
		const errors = getCtrlErrors(form, ctrlName as keyof T);
		if (errors) {
			formValid = false;
			break;
		}
	}

	return formValid;
};

/**
 * 檢測單一欄位是否合格
 *
 * @template T
 * @param {T} form
 * @param {keyof T} ctrlName
 * @return {*}  {boolean}
 */
const isCtrlValid = <T extends object>(form: T, ctrlName: keyof T): boolean =>
	!getCtrlErrors<T>(form, ctrlName);

const getCtrlErrorMsg = (errors: FormErrors): string => {
	let errorMsg = '';
	const firstErrorName = errors && Object.keys(errors)[0];

	switch (firstErrorName) {
		case ValidationRules.REQUIRED:
			errorMsg = '必填欄位';
			break;
		case ValidationRules.PATTERN:
			errorMsg = '格式錯誤';
			break;

		default:
			break;
	}

	return errorMsg;
};

const getCtrlErrorMsgWithPrefix =
	(prefix: string) =>
		(errors: FormErrors): string => {
			const errorMsg = getCtrlErrorMsg(errors);
			return errorMsg ? `${prefix}${errorMsg}` : '';
		};

const getRawValue = <T extends object>(form: T): Record<keyof T, unknown> => {
	let rawValue = {};
	Object.keys(form).forEach(key => {
		const ctrlName = key as keyof T;
		const ctrlValue = (form[ctrlName] as FormControl).value;
		rawValue = {
			...rawValue,
			[key]: ctrlValue,
		};
	});
	return rawValue as Record<keyof T, unknown>;
};

const setCtrlValidators = <T>(
	form: T,
	ctrlName: keyof T,
	validators: ValidatorFnType[],
): Partial<T> => {
	const { options } = form[ctrlName] as FormControl;

	return {
		[ctrlName]: {
			...form[ctrlName],
			options: {
				...options,
				validators,
			},
		},
	} as Partial<T>;
};

export {
	patchValue,
	setCtrlValue,
	setCtrlErrors,
	getCtrlErrorMsg,
	updateValidity,
	updateCtrlValidity,
	isFormValid,
	isCtrlValid,
	getCtrlErrorMsgWithPrefix,
	getRawValue,
	setCtrlValidators,
};
