import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { api } from 'util/api';
import { V1TwmVerifyCreateRequestPayload } from 'util/api/swaggerApi/data-contracts';

import { State as GlobalState } from './reducers';

type ServerError = {
	status: number,
	message: string;
	errorCode: string;
}

// For Global State usage
export interface State {
	loading: boolean;
	isPageContentShow: boolean;
}

export const defaultState: State = {
	loading: false,
	isPageContentShow: false,
};

export const setIsPageContentShow = createAction<boolean, boolean>(
	'SET_IS_PAGE_CONTENT_SHOW',
	(value: boolean) => value,
);

export const getSurveyCakeQuestionnaireLink = createAction(
	'GET_SURVEY_CAKE_QUESTIONNAIRE_LINK',
	async (scVariable: string) => {
		const { v1OpenLinkDetail } = api;
		try {
			const { status, data } = await v1OpenLinkDetail(scVariable);
			if (status === 200) {
				const link = data?.data?.referralUrl || '';
				if (link) {
					window.location.href = link;
				}
			}
		} catch (error) {
			console.log(error);
		}
	},
);

export const getInnerAnonymousQuestionnaireLink = createAction(
	'GET_INNER_ANONYMOUS_QUESTIONNAIRE_LINK',
	async (svid: string, inputBody: V1TwmVerifyCreateRequestPayload) => {
		const { v1TwmVerifyCreate } = api;
		try {
			const { status, data } = await v1TwmVerifyCreate(svid, inputBody);
			if (status === 200) {
				const link = data?.data?.referralUrl || '';
				if (link) {
					window.location.href = link;
				}
			}
		} catch (error) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const serverError: ServerError = (error as any).error;
			if (serverError.message) {
				// eslint-disable-next-line no-alert
				alert(serverError.message);
			}
		}
	},
);

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	leadPage: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_IS_PAGE_CONTENT_SHOW: (state: State, action: Action<boolean>) => ({
				...state,
				isPageContentShow: action.payload,
			}),
		},
		defaultState,
	),
};

const leadPageActionsMap = {
	setIsPageContentShow,
};

const mapHooksToState = (state: GlobalState) => ({
	loading: state.leadPage.loading,
	isPageContentShow: state.leadPage.isPageContentShow,
});

type LeadPageSelector = ReturnType<typeof mapHooksToState>;
type LeadPageActionsMap = typeof leadPageActionsMap;

export const useLeadPage = () =>
	useRedux<LeadPageSelector, LeadPageActionsMap>(mapHooksToState, leadPageActionsMap);
