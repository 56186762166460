import { combineReducers } from 'redux';

import * as routing from './routing';
import * as members from './member';
import * as blogs from './blog';
import * as banners from './banner';
import * as leadPage from './leadPage';
import * as leadForm from './leadForm';

// For Global State interface
export interface State {
	blogs: blogs.State;
	banners: banners.State;
	members: members.State;
	routing: routing.State;
	leadPage: leadPage.State;
	leadForm: leadForm.State;
}

export type GetState = () => State;

export const defaultState: State = {
	blogs: blogs.defaultState,
	banners: banners.defaultState,
	members: members.defaultState,
	routing: routing.defaultState,
	leadPage: leadPage.defaultState,
	leadForm: leadForm.defaultState,
};

const reducers = combineReducers<State>({
	...routing.reducer,
	...members.reducer,
	...blogs.reducer,
	...banners.reducer,
	...leadPage.reducer,
	...leadForm.reducer,
});

export default reducers;
