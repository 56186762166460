import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';

import { FormControl } from 'util/formSystem/formControl';
import {
	getRawValue,
	isFormValid,
	setCtrlValue,
	updateCtrlValidity,
	updateValidity,
} from 'util/formSystem/formOperators';
import { Validators } from 'util/formSystem/validatorFn';
import { V1TwmVerifyCreateRequestPayload } from 'util/api/swaggerApi/data-contracts';
import { getInnerAnonymousQuestionnaireLink } from './leadPage';

import { GetState, State as GlobalState } from './reducers';

// For Global State usage
export interface State {
	uuid: FormControl<string>;
	name: FormControl<string>;
}

export const defaultState: State = {
	uuid: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.require],
		},
	},
	name: {
		value: '',
		errors: null,
		options: {
			validators: [Validators.require],
		},
	},
};

const setupFormModel = <F extends object>(
	formName: string,
	selector: (getState: GetState) => F,
) => {
	const setFormCtrlValue = createAction(
		`${formName}_SET_CTRL_VALUE`,
		<R>(ctrlName: keyof F, value: R) =>
			(_: Dispatch, getState: GetState) => {
				const form = selector(getState);
				return setCtrlValue<F, R>(form as F, ctrlName, value);
			},
	);

	const updateFormValidity = createAction(
		`${formName}_UPDATE_FORM_VALIDITY`,
		() => (_: Dispatch, getState: GetState) => {
			const form = selector(getState);
			return updateValidity<F>(form);
		},
	);

	const updateFormCtrlValidity = createAction(
		`${formName}_UPDATE_CTRL_VALIDITY`,
		(ctrlName: keyof F) => (_: Dispatch, getState: GetState) => {
			const form = selector(getState);
			return updateCtrlValidity<F>(form, ctrlName);
		},
	);

	return {
		actions: { setFormCtrlValue, updateFormValidity, updateFormCtrlValidity },
		reducers: {
			[`${formName}_SET_CTRL_VALUE`]: (state: State, action: Action<Partial<State>>) => ({
				...state,
				...action.payload,
			}),
			[`${formName}_UPDATE_FORM_VALIDITY`]: (state: State, action: Action<State>) => ({
				...state,
				...action.payload,
			}),
			[`${formName}_UPDATE_CTRL_VALIDITY`]: (state: State, action: Action<Partial<State>>) => ({
				...state,
				...action.payload,
			}),
		},
	};
};

const formModelConfig = setupFormModel<State>('LEAD_FORM', (getState: GetState) => {
	const { leadForm } = getState();
	return leadForm;
});

const submitForm = createAction('SUBMIT_FORM', () => (dispatch: Dispatch, getState: GetState) => {
	dispatch(formModelConfig.actions.updateFormValidity());
	const { leadForm } = getState();
	const isValid = isFormValid(leadForm);

	if (!isValid) {
		return;
	}

	const {
		routing: { pathname }
	} = getState();

	const routeTree = pathname.split('/').filter(name => name);
	const rawValue = getRawValue(leadForm) as V1TwmVerifyCreateRequestPayload;
	dispatch(getInnerAnonymousQuestionnaireLink(routeTree[0], rawValue));

});

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	leadForm: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
		},
		defaultState,
	),
};

const LeadFormActionsMap = {
	...formModelConfig.actions,
	submitForm,
};

const mapHooksToState = (state: GlobalState) => ({
	leadForm: state.leadForm,
});

type LeadFormSelector = ReturnType<typeof mapHooksToState>;
type LeadFormActionsMap = typeof LeadFormActionsMap;

export const useLeadForm = () =>
	useRedux<LeadFormSelector, LeadFormActionsMap>(mapHooksToState, LeadFormActionsMap);
