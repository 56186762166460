/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

interface AppProperty {
	children: React.ReactNode;
}

const App: React.FC<AppProperty> = ({ children }) => (
	<>
		{children}
	</>
);

export default App;
